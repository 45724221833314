import React from "react";
import useStore from "../state";

const Header = ({ isHomepage }) => {
    const { status } = useStore((state) => state.darkMode);

    return (
        <header>
            <svg
                className="mx-auto my-3"
                width="52"
                height="60"
                viewBox="0 0 52 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M23.5594 3.07715C25.6545 3.11823 27.6293 3.37162 29.4823 3.81482M29.4823 3.81482C41.6143 6.71666 48.5237 17.7557 49.7789 30.6218C50.584 38.8735 46.4821 49.5793 39.3668 54.191C32.4517 58.6731 20.1468 59.5899 13.1473 54.6643C7.22116 50.494 5.17515 41.186 3.53981 34.6447C2.19497 29.2653 1.75489 23.1508 2.3093 17.6067C2.81624 12.5373 7.19331 10.0724 10.355 6.91069C13.2175 4.0482 27.4725 -1.54195 29.4823 3.81482ZM29.4823 3.81482C29.4963 3.85236 29.5098 3.89043 29.5227 3.92905M37.1898 33.7455C35.5419 41.9848 28.0807 48.2019 19.3473 47.3285C14.8352 46.8773 15.0404 39.7402 15.0404 36.3012M20.1518 20.967C22.4035 22.511 24.2281 23.5227 26.967 23.5227M8.22525 20.967C9.89646 21.1727 18.4207 23.2892 14.1885 20.1151"
                    stroke={`${status ? "white" : "black"}`}
                    strokeWidth="3"
                    strokeLinecap="round"
                />
            </svg>
            <h1 className="bg-white dark:bg-gray-800 p-2 md:text-2xl text-center">
                Le blog de Arthur Dubois
            </h1>
        </header>
    );
};

export default Header;
