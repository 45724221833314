import React, { Fragment } from "react";
import { Link, graphql } from "gatsby";
import useStore from "../state";
import Seo from "../components/SEO";
import Header from "../components/Header";

const IndexPage = ({ pageTitle, data }) => {
    const { status } = useStore((state) => state.darkMode);
    const { edges } = data.allMarkdownRemark;

    const posts = edges.sort().filter((e, i) => i <= 7);

    return (
        <Fragment>
            <Seo title="Le meilleur site de renseignements" />
            <section
                className={`${
                    status ? "dark bg-gray-800 text-red-50" : "light"
                } p-3 w-full mx-auto mt-6 md:max-w-md`}
            >
                <Header />
                <ul className="pb-2">
                    {posts.map(({ node: post }) => (
                        <li className="mb-3" key={post.id}>
                            <h3 className="text-lg font-bold text-green-400 hover:text-green-300 dark:text-indigo-400">
                                <Link to={post.frontmatter.path}>
                                    {post.frontmatter.title}
                                </Link>
                            </h3>
                            <span className="text-gray-800 dark:text-gray-100 italic text-right">
                                {post.frontmatter.date}
                            </span>
                            <article className="text-xs">
                                {post.excerpt}
                            </article>
                        </li>
                    ))}
                </ul>
            </section>
        </Fragment>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexQuery {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    id
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
                        path
                    }
                }
            }
        }
    }
`;
